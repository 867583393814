<template>
<div>
    <v-container class="container--fluid grid-list-md text-center">
        <router-view></router-view>
    </v-container>
</div>
</template>

<script>
export default {
    name: 'Users',
};
</script>
